
import Vue from "vue";
import CustomAlert from "@/components/CustomAlert/CustomAlert.vue";
import { authMapGetters } from "@/store/modules/auth";
import { get } from "lodash";
interface ICompanyCompanyData {
  errorMessage: string;
  isLoading: boolean;
  successMessage: string;
}
export default Vue.extend({
  name: "agent-reports-portal",
  components: {
    CustomAlert,
    AgentReportsPortalList: () =>
      import(
        "@/components/AgentReportsPortal/ListPortal/AgentReportsPortalList.vue"
      )
  },
  data(): ICompanyCompanyData {
    return {
      successMessage: "",
      errorMessage: "",
      isLoading: false
    };
  },
  methods: {
    onToolbarItemClick(event: string) {
      switch (event) {
        case "checkUploadedAgentReports":
          this.$router.push(`/admin/upload/agent-report`).catch(() => {});
          break;
        case "view-activities":
          this.$router.push(`/agent-report/activities`).catch(() => {});
          break;
      }
    },
    doSetErrorMessage(error: string): void {
      this.errorMessage = error;
    }
  },
  computed: {
    ...authMapGetters(["getCurrentUser"]),
    getUserRole(): string {
      return get(this.getCurrentUser, "role", "");
    },
    primaryButton() {
      const role = this.getUserRole;

      if (role === "admin") {
        // return {
        //   text: "Upload Agent Reports",
        //   key: "uploadAgenttReports"
        // };
        // return {
        //   text: "See uploaded agent Reports",
        //   key: "checkUploadedAgentReports"
        // };
      }
      return null;
    },
    actionButtonSecondary(): any {
      return this.getUserRole === "admin"
        ? {
            text: "Actions",
            key: "actions",
            subItems: [
              {
                title: "View Activities",
                command: "view-activities"
              }
            ]
          }
        : null;
    }
  }
});
